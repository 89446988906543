import './App.css';
import Waffle from "./components/Waffle/Waffle";

function App() {
    
  // let click = false;
  // 
  // 
  // function handleClick() {
  //     click = true;
  //     console.log(click);
  // }
    
  return (
    <div className="App" >
      <Waffle />
    </div>
  );
}

export default App;
